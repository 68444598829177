import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';

import {
  Pump,
  Valve,
  Pipe,
  Pond,
  PipePlaceholder,
  MiddlePipeL,
  ValveConnect,
  WaterTank,
  AirTank,
  Compressor,
  TriplePipeFork,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Weather,
  Info,
  // Totals,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  PLCColorRouter,
  Alarms,
  LoadingIndicator,
} from 'isno/lib/components/displays';

import {
  AutoValveControl,
  VFDPumpControl,
  ToggleControl,
  AlarmReset,
  MultiInfoButton,
  SequenceControl,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function OldPHScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;
  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(2),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(2);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const locationEquipment2 = props.locations?.locations?.[2]?.equipment;
  if (!props.locations?.locations?.[2]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  const useCelsius = props.settings?.settings?.useCelsius;

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="Old Pumphouse">
            <InfoControlRow>
              <MobileStatTable
                title="System Metrics"
                stats={
                [
                  {
                    description: 'Discharge Water Flow',
                    stat: locationEquipment2?.Other?.ioValues?.discharge_water_flow?.value,
                    label: locationEquipment2?.Other?.ioValues?.discharge_water_flow?.ioValueType?.units,
                  },
                  {
                    description: 'Discharge Water Pressure',
                    stat: locationEquipment2?.Other?.ioValues?.discharge_water_pressure?.value,
                    label: locationEquipment2?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Discharge Water Temperature',
                    stat: useCelsius ? locationEquipment2?.Other?.ioValues?.discharge_water_temperature_alternate?.value : locationEquipment2?.Other?.ioValues?.discharge_water_temperature?.value,
                    label: useCelsius ? locationEquipment2?.Other?.ioValues?.discharge_water_temperature_alternate?.ioValueType?.units : locationEquipment2?.Other?.ioValues?.discharge_water_temperature?.ioValueType?.units,
                    precision: 1,
                  },
                ]
              }
              />
              <VFDPumpControl
                title="P2 PID"
                settings
                inputNumWidth="64px"
                pumpData={{ id: `controlValve_${locationEquipment2?.['P2 PID']?.id}` }}
                pidData={locationEquipment2?.['P2 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  iGainSetpoint: 'igain_setpoint',
                  pGainSetpoint: 'pgain_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
              />
              <VFDPumpControl
                title="P3 PID"
                settings
                inputNumWidth="64px"
                pumpData={{ id: `controlValve_${locationEquipment2?.['P3 PID']?.id}` }}
                pidData={locationEquipment2?.['P3 PID']}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_manual',
                  manualMode: 'auto_manual',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                  iGainSetpoint: 'igain_setpoint',
                  pGainSetpoint: 'pgain_setpoint',
                }}
                readValues={[0, 1]}
                writeValues={[0, 1]}
              />
              <SequenceControl
                title="Sequencer"
                pumpsHeight="438px"
                pumpsWidth="193px"
                setIOValue={props.setIOValue}
                sequencerData={locationEquipment2?.Sequencer}
                leadVFD={locationEquipment2?.Sequencer?.ioValues?.sequence_lead_vfd}
                leadVFDId={locationEquipment2?.Sequencer?.ioValues?.sequence_lead_vfd?.value === 1 ? locationEquipment2?.P3?.id : locationEquipment2?.P2?.id}
                leadVFDlabel1={locationEquipment2?.P2?.name}
                leadVFDlabel2={locationEquipment2?.P3?.name}
                id={locationEquipment2?.Sequencer?.id}
                readValues={[1, 0]}
                writeValues={[1, 0]}
                pumps={[
                  locationEquipment2?.P1,
                  locationEquipment2?.P2,
                  locationEquipment2?.P3,
                  locationEquipment2?.P4,
                ]}
                disableEnable={[
                  locationEquipment2?.Sequencer?.ioValues?.p1_disable_enable,
                  locationEquipment2?.Sequencer?.ioValues?.p2_disable_enable,
                  locationEquipment2?.Sequencer?.ioValues?.p3_disable_enable,
                  locationEquipment2?.Sequencer?.ioValues?.p4_disable_enable,
                ]}
                setpoints={[
                  locationEquipment2?.Sequencer?.ioValues?.p1_sequence_order_setpoint,
                  locationEquipment2?.Sequencer?.ioValues?.p2_sequence_order_setpoint,
                  locationEquipment2?.Sequencer?.ioValues?.p3_sequence_order_setpoint,
                  locationEquipment2?.Sequencer?.ioValues?.p4_sequence_order_setpoint,
                ]}
                startParam={[
                  locationEquipment2?.Sequencer?.ioValues?.start_fast_offset_setpoint,
                  locationEquipment2?.Sequencer?.ioValues?.start_fast_timer_setpoint,
                  locationEquipment2?.Sequencer?.ioValues?.start_slow_offset_setpoint,
                  locationEquipment2?.Sequencer?.ioValues?.start_slow_timer_setpoint,
                ]}
                startValues={[
                  locationEquipment2?.Sequencer?.ioValues?.start_fast_offset,
                  locationEquipment2?.Sequencer?.ioValues?.start_fast_timer,
                  locationEquipment2?.Sequencer?.ioValues?.start_slow_offset,
                  locationEquipment2?.Sequencer?.ioValues?.start_slow_timer,
                ]}
                startLabels={[
                  'Fast Offset',
                  'Fast Timer',
                  'Slow Offset',
                  'Slow Timer',
                ]}
                stopParam={[
                  locationEquipment2?.Sequencer?.ioValues?.stop_fast_offset_setpoint,
                  locationEquipment2?.Sequencer?.ioValues?.stop_fast_timer_setpoint,
                  useCelsius ? locationEquipment2?.Sequencer?.ioValues?.stop_fast_seal_setpoint_alternate : locationEquipment2?.Sequencer?.ioValues?.stop_fast_seal_setpoint,
                  locationEquipment2?.Sequencer?.ioValues?.stop_fast_speed_setpoint,
                  locationEquipment2?.Sequencer?.ioValues?.stop_slow_offset_setpoint,
                  locationEquipment2?.Sequencer?.ioValues?.stop_slow_timer_setpoint,
                  useCelsius ? locationEquipment2?.Sequencer?.ioValues?.stop_slow_seal_setpoint_alternate : locationEquipment2?.Sequencer?.ioValues?.stop_slow_seal_setpoint,
                  locationEquipment2?.Sequencer?.ioValues?.stop_slow_speed_setpoint,
                ]}
                stopValues={[
                  locationEquipment2?.Sequencer?.ioValues?.stop_fast_offset,
                  locationEquipment2?.Sequencer?.ioValues?.stop_fast_timer,
                  null,
                  null,
                  locationEquipment2?.Sequencer?.ioValues?.stop_slow_offset,
                  locationEquipment2?.Sequencer?.ioValues?.stop_slow_timer,
                  null,
                  null,
                ]}
                stopLabels={[
                  'Fast Offset',
                  'Fast Timer',
                  'Fast Seal',
                  'Fast Speed',
                  'Slow Offset',
                  'Slow Timer',
                  'Slow Seal',
                  'Slow Speed',
                ]}
              />
              <ToggleControl
                title="Charge Mode"
                ioData={locationEquipment2.Other}
                IOValueKeys={{
                  start: 'charge_mode_disable_enable',
                  stop: 'charge_mode_disable_enable',
                  started: 'charge_mode',
                  stopped: 'charge_mode',
                }}
                setIOValue={props.setIOValue}
                button1Text="Enable"
                button2Text="Disable"
                readValues={[1, 0]}
                writeValues={[1, 0]}
                dot
                buttonWidth="58px"
              />
              <MultiInfoButton
                title="Flow Totals"
                titles={['Trip', 'Total']}
                statistics={[
                  locationEquipment2?.FT?.ioValues?.trip_volume?.value,
                  locationEquipment2?.FT?.ioValues?.total_volume?.value,
                ]}
                labels={[
                  locationEquipment2?.FT?.ioValues?.trip_volume?.ioValueType?.units,
                  locationEquipment2?.FT?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                precisions={[0, 0]}
                buttonWriteIds={[
                  locationEquipment2?.FT?.ioValues?.trip_volume_reset?.id,
                  locationEquipment2?.FT?.ioValues?.total_volume_reset?.id,
                ]}
                buttonWriteValues={[1, 1]}
                buttonTexts={['Reset', 'Reset']}
                setIOValue={props.setIOValue}
              />
              <Info
                title="Room Temp."
                statistic={useCelsius ? locationEquipment2?.Other?.ioValues?.room_temperature_alternate?.value : locationEquipment2?.Other?.ioValues?.room_temperature?.value}
                label={useCelsius ? locationEquipment2?.Other?.ioValues?.room_temperature_alternate?.ioValueType?.units : locationEquipment2?.Other?.ioValues?.room_temperature?.ioValueType?.units}
                precision={1}
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment2?.Other?.ioValues}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment2?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarmReset: 'alarm_reset',
                  masterAlarm: 'master_alarm',
                }}
                resetWriteValue={1}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[2], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[2], theme)}
              />
            </InfoControlRow>
            <PumpsRowExtraSmall styles={styles.hiddenMobile}>
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
              <div css={styles.flexRowBox}>
                <PipePlaceholder minWidth="51px" pipeAbove="right" />
                <div css={[css`margin-top: -3px; display: flex; flex-direction: row;`, styles.hiddenMobile]}>
                  <Statistic
                    label={locationEquipment2?.Other?.ioValues[IOValueKeys.dischargePressure]?.ioValueType?.units}
                    border
                    statistic={locationEquipment2?.Other?.ioValues[IOValueKeys.dischargePressure]?.value}
                  />
                  <Statistic
                    statistic={locationEquipment2?.Other?.ioValues?.discharge_water_flow?.value}
                    border
                    label={locationEquipment2?.Other?.ioValues?.discharge_water_flow?.ioValueType?.units}
                  />
                  <Statistic
                    statistic={useCelsius ? locationEquipment2?.Other?.ioValues?.discharge_water_temperature_alternate?.value : locationEquipment2?.Other?.ioValues?.discharge_water_temperature?.value}
                    border
                    label={useCelsius ? locationEquipment2?.Other?.ioValues?.discharge_water_temperature_alternate?.ioValueType?.units : locationEquipment2?.Other?.ioValues?.discharge_water_temperature?.ioValueType?.units}
                    precision={1}
                  />
                </div>
              </div>
              <PipePlaceholder horizontalAbove />
              <div css={styles.flexRowBox}>
                <PipePlaceholder pipeAbove="middle" minWidth="0px" />
                <PipePlaceholder minWidth="0px" horizontalAbove capAfter capLabel="To Hill" capLabelLeft="-40px" capLabelTop="15px" />
              </div>
            </PumpsRowExtraSmall>
            <PumpsRowMedium>
              <Pump
                pipeAbove="right"
                percent
                setIOValue={props.setIOValue}
                pumpData={locationEquipment2?.P1}
                otherData={locationEquipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                readValues={[1, 0]}
                IOValueKeys={{
                  started: 'stop_start',
                  stopped: 'stop_start',
                  start: 'start',
                  stop: 'stop',
                }}
                alarms={[
                  locationEquipment2?.P1?.ioValues?.fault,
                  locationEquipment2?.P1?.ioValues?.low_amperage,
                  locationEquipment2?.P1?.ioValues?.high_seal_temperature,
                  locationEquipment2?.P1?.ioValues?.master_alarm,
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.P1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment2?.P1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.P1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                  {
                    stat: useCelsius ? locationEquipment2?.P1?.ioValues?.seal_temperature_alternate?.value : locationEquipment2?.P1?.ioValues?.seal_temperature?.value,
                    label: useCelsius ? locationEquipment2?.P1?.ioValues?.seal_temperature_alternate?.ioValueType?.units : locationEquipment2?.P1?.ioValues?.seal_temperature?.ioValueType?.units,
                    precision: 1,
                  },
                ]}
              />
              <Pump
                pipeAbove="middle"
                percent
                setIOValue={props.setIOValue}
                pumpData={locationEquipment2?.P2}
                otherData={locationEquipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                readValues={[1, 0]}
                IOValueKeys={{
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  start: 'start',
                  stop: 'stop',
                  speed: 'speed',
                }}
                alarms={[
                  locationEquipment2?.P2?.ioValues?.fault,
                  locationEquipment2?.P2?.ioValues?.low_amperage,
                  locationEquipment2?.P2?.ioValues?.high_seal_temperature,
                  locationEquipment2?.P2?.ioValues?.master_alarm,
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.P2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment2?.P2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.P2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                  {
                    stat: useCelsius ? locationEquipment2?.P2?.ioValues?.seal_temperature_alternate?.value : locationEquipment2?.P2?.ioValues?.seal_temperature?.value,
                    label: useCelsius ? locationEquipment2?.P2?.ioValues?.seal_temperature_alternate?.ioValueType?.units : locationEquipment2?.P2?.ioValues?.seal_temperature?.ioValueType?.units,
                    precision: 1,
                  },
                ]}
              />
              <Pump
                pipeAbove="middle"
                percent
                setIOValue={props.setIOValue}
                pumpData={locationEquipment2?.P3}
                otherData={locationEquipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                readValues={[1, 0]}
                IOValueKeys={{
                  started: 'run_feedback',
                  stopped: 'run_feedback',
                  start: 'start',
                  stop: 'stop',
                  speed: 'speed',
                }}
                alarms={[
                  locationEquipment2?.P3?.ioValues?.fault,
                  locationEquipment2?.P3?.ioValues?.low_amperage,
                  locationEquipment2?.P3?.ioValues?.high_seal_temperature,
                  locationEquipment2?.P3?.ioValues?.master_alarm,
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.P3?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment2?.P3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P3?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.P3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                  {
                    stat: useCelsius ? locationEquipment2?.P3?.ioValues?.seal_temperature_alternate?.value : locationEquipment2?.P3?.ioValues?.seal_temperature?.value,
                    label: useCelsius ? locationEquipment2?.P3?.ioValues?.seal_temperature_alternate?.ioValueType?.units : locationEquipment2?.P3?.ioValues?.seal_temperature?.ioValueType?.units,
                    precision: 1,
                  },
                ]}
              />
              <Pump
                pipeAbove="left"
                percent
                setIOValue={props.setIOValue}
                pumpData={locationEquipment2?.P4}
                otherData={locationEquipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                readValues={[1, 0]}
                IOValueKeys={{
                  started: 'stop_start',
                  stopped: 'stop_start',
                  start: 'start',
                  stop: 'stop',
                }}
                alarms={[
                  locationEquipment2?.P4?.ioValues?.fault,
                  locationEquipment2?.P4?.ioValues?.low_amperage,
                  locationEquipment2?.P4?.ioValues?.high_seal_temperature,
                  locationEquipment2?.P4?.ioValues?.master_alarm,
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.P4?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment2?.P4?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P4?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.P4?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                  {
                    stat: useCelsius ? locationEquipment2?.P4?.ioValues?.seal_temperature_alternate?.value : locationEquipment2?.P4?.ioValues?.seal_temperature?.value,
                    label: useCelsius ? locationEquipment2?.P4?.ioValues?.seal_temperature_alternate?.ioValueType?.units : locationEquipment2?.P4?.ioValues?.seal_temperature?.ioValueType?.units,
                    precision: 1,
                  },
                ]}
              />
              <ValveConnect
                showOnMobile
                percent
                localRemote
                equipmentControl
                percentTop="52px"
                percentLeft="-10px"
                nameTop="-19px"
                nameLeft="36px"
                nameWidth="37px"
                controls
                controlsTop="-21px"
                controlsLeft="46px"
                valveData={{ ...locationEquipment2?.DV, name: 'DV' }}
                IOValueKeys={{
                  open: 'open',
                  close: 'close',
                  opened: 'opened',
                  closed: 'closed',
                  local: 'manual_auto',
                  remote: 'manual_auto',
                }}
                valveStateKeys={{
                  0: 'Closed',
                  1: 'Opened',
                  2: 'Moving',
                }}
                valveStateColorKeys={{
                  0: theme.offRed,
                  1: theme.onGreen,
                  2: theme.warning,
                }}
                setIOValue={props.setIOValue}
                readValues={[1, 1]}
                writeValues={[1, 1]}
              />
            </PumpsRowMedium>
            <PumpsRowExtraSmall>
              <Pond
                color={theme.pipeColors.water}
                id={`Lake_Info_${locationEquipment2?.id}`}
              >
                <Info
                  title="Well Level"
                  statistic={locationEquipment2?.Other?.ioValues?.pit_level?.value}
                  precision={1}
                  label={locationEquipment2?.Other?.ioValues?.pit_level?.ioValueType?.units}
                />
              </Pond>
            </PumpsRowExtraSmall>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s; 
    `,
    hiddenMobile: css`
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    flexRowBox: css`
      display: flex;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

OldPHScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,
};

OldPHScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
  settings: state.settings,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(OldPHScreen);
